import React, { useMemo, useState, useEffect } from 'react'
import './Monitoring.css'
import { InterventionT } from '../helpers/types'
import { http, useAuthQuery } from '../helpers/http'
import classnames from 'classnames'
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns'
import Loader from './Loader'
import { useRecoilState } from 'recoil'
import { filters_state } from '../states/filters.state'
import lodash from 'lodash'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { user_state } from '../states/user.state'

let hasnt_responded = (intervention: InterventionT) => {
  return differenceInMinutes(new Date(), new Date(intervention.HORODATAGE.seconds * 1000)) >= 20
} // If more than 20 minutes

let Monitoring: React.FC = () => {
  let [sortAttribute, setSortAttribute] = useState('machine_location')
  let [order, setOrder] = useState('Asc')
  let [modalOpen, setmodalOpen] = useState(false)
  let [modalRebootOpen, setModalRebootOpen] = useState(false)
  let [modalunlockDoorsOpen, setModalunlockDoorsOpen] = useState(false)
  // let [modalDisabelMachine, setModalDisableMachine] = useState(false)
  let [comment, setComment] = useState('')
  let [interventionToComment, setInterventionToComment] = useState<InterventionT>()
  let [selectedIntervention, setSelectedIntervention] = useState<InterventionT>()

  let { isLoading, isError, data: interventions } = useAuthQuery<InterventionT[]>('interventions', '/interventions')
  let [intervention2, setIntervention2] = useState(interventions)
  let [user] = useRecoilState(user_state)

  useEffect(() => {
    setIntervention2(interventions)
  }, [interventions]) // interventions devient intervention2 chaque fois que interventions est modifié.
  // if (interventions) {
  //   const monIntervention = interventions[23]
  //   // ajouter à l'objet monIntervention la donnée COMMENTS de PERSONNALISATION
  // }

  let [{ search }] = useRecoilState(filters_state)

  let rebootMachine = async () => {
    try {
      await http.post(
        '/rebootMachine',
        { machineID: selectedIntervention.machine_id },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        },
      )
      setModalRebootOpen(false)

      let tmpInterventions = intervention2.map((i) => {
        if (i.machine_id === selectedIntervention.machine_id) {
          return { ...i, reboot_Machine: 'Redémarrage en cours' }
        } else return i
      })

      setIntervention2(tmpInterventions)
    } finally {
    }
  }

  let unlockingDoors = async () => {
    try {
      await http.post(
        'unlockDoors',
        { machineID: selectedIntervention.machine_id },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        },
      )

      setModalunlockDoorsOpen(false)

      let tmpInterventions = intervention2.map((i) => {
        if (i.machine_id === selectedIntervention.machine_id) {
          return { ...i, unlock_doors: 'Ouverture des Portes' }
        } else return i
      })

      setIntervention2(tmpInterventions)
    } finally {
    }
  }

  let lockingDoors = async () => {
    try {
      await http.post(
        'lockDoors',
        { machineID: selectedIntervention.machine_id },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        },
      )

      let tmpInterventions = intervention2.map((i) => {
        if (i.machine_id === selectedIntervention.machine_id) {
          return { ...i, unlock_doors: 'fermeture dans 15s' }
        } else return i
      })

      setIntervention2(tmpInterventions)
    } finally {
    }
  }

  // let disableMachine = async () => {
  //   try {
  //     await http.post(
  //       'disablemachine',
  //       { machineID: selectedIntervention.machine_id },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${user?.token}`,
  //         },
  //       },
  //     )

  //     setModalDisableMachine(false)

  //     let tmpInterventions = intervention2.map((i) => {
  //       if (i.machine_id === selectedIntervention.machine_id) {
  //         return { ...i, machine_disable: 'Machine Verouillée' }
  //       } else return i
  //     })

  //     setIntervention2(tmpInterventions)
  //   } finally {
  //   }
  // }

  let commentUpdate = async (newComment) => {
    try {
      await http.post(
        '/commentUpdate',
        {
          comment: newComment,
          machineID: interventionToComment.machine_id,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        },
      )
      setmodalOpen(false)

      let tmpIntervention = intervention2.map((i) => {
        if (i.machine_id === interventionToComment.machine_id) {
          return { ...i, comment: newComment }
        } else return i
      })

      setIntervention2(tmpIntervention)
    } finally {
    }
  }

  let reset = () => {
    setComment('')
    commentUpdate('')
  }

  let interventions_in_range = useMemo(() => {
    let filteredInterventions = intervention2
    // if (startDate && endDate) {
    //   filteredInterventions = interventions?.filter((intervention: InterventionT) => {
    //     const transactionDate = new Date(intervention.HORODATAGE.seconds * 1000)
    //     return (
    //       isWithinInterval(transactionDate, { start: startDate, end: endDate }) || isSameDay(transactionDate, startDate)
    //     )
    //   })
    // }

    if (search) {
      filteredInterventions = filteredInterventions?.filter((i: InterventionT) => {
        return i.machine_location?.toLowerCase()?.includes(search?.toLowerCase())
      })
    }

    return filteredInterventions
  }, [intervention2, search])

  let interventions_by_location: {
    [machine_location: string]: InterventionT[]
  } = lodash.groupBy(interventions_in_range, 'machine_location')

  let formatted_interventions = Object.keys(interventions_by_location)
    .map((t) => {
      let intervention = interventions_by_location[t][0]
      let horodatage = new Date(intervention.HORODATAGE.seconds * 1000)
      intervention.hasnt_responded = hasnt_responded(intervention)
      intervention.horodatage = horodatage.toLocaleString()
      intervention.last_transaction_date = intervention.last_transaction?.seconds
        ? new Date(intervention.last_transaction.seconds * 1000)
        : null

      return intervention
    })
    // sort by machine_location
    .sort((a: any, b: any): any => {
      let attribute = sortAttribute ? sortAttribute : 'id'
      if (a[attribute] > b[attribute]) {
        return order === 'Asc' ? 1 : -1
      } else {
        return order === 'Asc' ? -1 : 1
      }
    })
  // Agréger le champs COMMENTS au tableau interventions

  // const number_of_machines_that_have_not_responded = formatted_interventions.filter((i) => i.hasnt_responded).length

  let number_of_machines_that_have_responded = formatted_interventions.filter((i) => !i.hasnt_responded).length

  let sortIcon =
    order === 'Asc' ? (
      <IonIcon name="arrow-down-circle-outline"></IonIcon>
    ) : (
      <IonIcon name="arrow-up-circle-outline"></IonIcon>
    )

  if (isLoading) {
    return <Loader />
  }

  if (isError || !intervention2) {
    return <div>Erreur</div>
  }

  return (
    <div className="tab_container">
      <table>
        <thead>
          <tr>
            <th
              colSpan={9}
              style={{ textAlign: 'center', color: '#00FF00' }}
              onClick={() => {
                document.getElementById('PR').scrollIntoView({
                  behavior: 'smooth',
                })
              }}
            >
              {' '}
              <h4 id="PR">MACHINES EN PRODUCTION</h4>
            </th>
            <th>
              <IonButton
                style={{ textAlign: 'right', color: '#00FF00' }}
                color="danger"
                fill="outline"
                onClick={() => {
                  document.getElementById('HP').scrollIntoView({
                    behavior: 'smooth',
                  })
                }}
              >
                Hors Production
              </IonButton>
            </th>
          </tr>
          <tr>
            <th
              onClick={() => {
                setSortAttribute('HORODATAGE')
                setOrder(order === 'Asc' ? 'Des' : 'Asc')
              }}
            >
              Date {sortIcon}
            </th>
            <th
              onClick={() => {
                setSortAttribute('machine_id')
                setOrder(order === 'Asc' ? 'Des' : 'Asc')
              }}
            >
              Numéro {sortIcon}
            </th>
            <th
              onClick={() => {
                setSortAttribute('machine_location')
                setOrder(order === 'Asc' ? 'Des' : 'Asc')
              }}
            >
              Localisation {sortIcon}
            </th>

            <th
              className="center"
              onClick={() => {
                setSortAttribute('comment')
                setOrder(order === 'Asc' ? 'Des' : 'Asc')
              }}
            >
              Commentaires {sortIcon}
            </th>
            <th className="center">Reboot</th>
            <th className="center">Portes</th>
            {/* <th className="center">Verouiller</th> */}
            <th
              className="center"
              onClick={() => {
                setSortAttribute('STOCK')
                setOrder(order === 'Asc' ? 'Des' : 'Asc')
              }}
            >
              Stock {sortIcon}
            </th>

            <th
              className="center"
              onClick={() => {
                setSortAttribute('TEMP_CF')
                setOrder(order === 'Asc' ? 'Des' : 'Asc')
              }}
            >
              Temp. Int. {sortIcon}
            </th>
            <th
              className="center"
              onClick={() => {
                setSortAttribute('TEMP_CC')
                setOrder(order === 'Asc' ? 'Des' : 'Asc')
              }}
            >
              Temp. Ext.{sortIcon}
            </th>
            <th
              className="center"
              onClick={() => {
                setSortAttribute('last_transaction_date')
                setOrder(order === 'Asc' ? 'Des' : 'Asc')
              }}
            >
              Dernière Vente {sortIcon}
            </th>
          </tr>
          <tr>
            <td colSpan={10}>
              Machines OnLine : <span className="success">{number_of_machines_that_have_responded}</span>/
              {intervention2.length}
            </td>
          </tr>
        </thead>

        {/* MODALE COMMENTAIRE */}
        <IonModal
          isOpen={modalOpen}
          onDidDismiss={() => {
            setmodalOpen(false)
          }}
          style={{
            height: '100%',
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setmodalOpen(false)}>
                  {' '}
                  {/* {When enable modal close window button (IonButton)} */}
                  Fermer
                </IonButton>
              </IonButtons>
              <IonTitle>
                {/* <IonButton strong={true} onClick={reset} shape="round" fill="outline" color="primary">
                  Reset
                </IonButton> */}
              </IonTitle>

              <IonButtons slot="end">
                <IonButton
                  strong={true}
                  onClick={() => {
                    commentUpdate(comment)
                  }}
                  shape="round"
                  fill="outline"
                  color="primary"
                >
                  Envoyer
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding" color="dark">
            <IonItem color="light">
              <IonLabel position="stacked"></IonLabel>
              <IonInput
                type="text"
                placeholder="Insérer votre commentaire"
                value={comment}
                onIonChange={(e: any) => {
                  setComment(e.detail.value)
                }}
              />
            </IonItem>
          </IonContent>
          <IonFooter>
            <IonButton strong={true} onClick={reset} shape="round" fill="outline" color="primary">
              Reset
            </IonButton>
          </IonFooter>
        </IonModal>

        {/* MODALE REBOOT MACHINE */}
        <IonModal
          isOpen={modalRebootOpen}
          onDidDismiss={() => {
            setModalRebootOpen(false)
          }}
          style={{
            height: '100%',
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton color="danger" fill="outline" onClick={() => setModalRebootOpen(false)}>
                  ANNULER
                </IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonButton
                  strong={true}
                  onClick={() => {
                    rebootMachine()
                    setModalRebootOpen(false)
                  }}
                  shape="round"
                  fill="outline"
                  color="primary"
                >
                  REBOOTER
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <h1 className="RebootModalText">
              Voulez-vous redémarrer le distributeur {selectedIntervention?.machine_location}
            </h1>
            <IonItem color="light"></IonItem>
          </IonContent>
        </IonModal>

        {/* MODALE DISABELING MACHINE */}
        {/* <IonModal
          isOpen={modalRebootOpen}
          onDidDismiss={() => {
            setModalDisableMachine(false)
          }}
          style={{
            height: '100%',
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton color="danger" fill="outline" onClick={() => setModalDisableMachine(false)}>
                  ANNULER
                </IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonButton
                  strong={true}
                  onClick={() => {
                    disableMachine()
                    setModalDisableMachine(false)
                  }}
                  shape="round"
                  fill="outline"
                  color="primary"
                >
                  VERROUILLAGE MACHINE
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <h1 className="RebootModalText">
              Voulez-vous verouiller le distributeur {selectedIntervention?.machine_location}
            </h1>
            <IonItem color="light"></IonItem>
          </IonContent>
        </IonModal> */}

        {/* MODALE OUVERTURE DES PORTES */}
        <IonModal
          isOpen={modalunlockDoorsOpen}
          onDidDismiss={() => {
            setModalunlockDoorsOpen(false)
          }}
          style={{
            height: '100%',
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton color="danger" fill="outline" onClick={() => setModalunlockDoorsOpen(false)}>
                  ANNULER
                </IonButton>
              </IonButtons>
              <IonButtons slot="end">
                <IonButton
                  strong={true}
                  onClick={() => {
                    unlockingDoors()
                    setTimeout(() => {
                      lockingDoors()
                    }, 10000)
                  }}
                  shape="round"
                  fill="outline"
                  color="primary"
                >
                  OUVERTURE DES PORTES
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <h1 className="RebootModalText">
              Voulez-vous ouvrir les portes de {selectedIntervention?.machine_location}
            </h1>
            <IonItem color="light"></IonItem>
          </IonContent>
        </IonModal>

        <tbody>
          {formatted_interventions
            .filter((intervention) => {
              return intervention.comment === ''
            })
            .map((intervention: any) => {
              return (
                <tr key={intervention.id}>
                  <td
                    className={classnames({
                      bg_danger: hasnt_responded(intervention),
                    })}
                  >
                    {intervention.horodatage}
                  </td>

                  <td>{intervention.machine_id}</td>
                  <td>{intervention.machine_location}</td>

                  <td className="center">
                    {intervention.comment}

                    <i
                      className="fa-sharp fa-regular fa-file-lines"
                      onClick={() => {
                        setInterventionToComment(intervention)
                        setComment(intervention.comment)
                        setmodalOpen(true)
                      }}
                    ></i>
                  </td>
                  <td
                    className={'center'}
                    onClick={() => {
                      setSelectedIntervention(intervention)
                      setModalRebootOpen(true)
                    }}
                  >
                    {intervention.reboot_Machine ? 'Redémarrage en cours' : ''}
                    <i className="fa-solid fa-bolt"></i>
                  </td>
                  <td
                    className={'center'}
                    onClick={() => {
                      setSelectedIntervention(intervention)
                      setModalunlockDoorsOpen(true)
                    }}
                  >
                    {intervention.unlock_doors ? (
                      <i className="fa-solid fa-lock-open"></i>
                    ) : (
                      <i className="fa-solid fa-lock"></i>
                    )}
                    {/*intervention.unlock_doors : unlock_doors attribut du tableau golbal dans le back*/}
                  </td>
                  {/* <td className="center">Portes</td> */}
                  <td
                    className={classnames(
                      {
                        bg_danger: intervention.STOCK < 50,
                        bg_warning: intervention.STOCK > 50 && intervention.STOCK < 250,
                        image_clignote: intervention.STOCK > 900,
                      },
                      'weight',
                      'center',
                    )}
                  >
                    {intervention.machine_Tare + intervention.STOCK === 0
                      ? 'Défaut Carte Pesée'
                      : `${intervention.STOCK} kg`}
                    {/* {intervention.STOCK}, {intervention.machine_Tare} */}
                    {/* {intervention.STOCK} kg */}
                  </td>

                  <td
                    className={classnames!!(
                      {
                        bg_warning: intervention.TEMP_CF > 0,
                      },
                      'temperature',
                      'center',
                    )}
                  >
                    {intervention.TEMP_CF} °C
                  </td>
                  <td
                    className={classnames(
                      {
                        bg_warning: intervention.TEMP_CC > 60,
                      },
                      'temperature',
                      'center',
                    )}
                  >
                    {intervention.TEMP_CC} °C
                  </td>
                  {intervention.last_transaction_date ? (
                    <td // Color cell flag change refering to different time options
                      className={classnames(
                        {
                          bg_danger: differenceInDays(new Date(), intervention.last_transaction_date) > 3, //Danger flag if last transaction is over 3 days
                          bg_warning:
                            differenceInDays(new Date(), intervention.last_transaction_date) <= 3 &&
                            differenceInDays(new Date(), intervention.last_transaction_date) > 1,
                          // Warning flag if last transaction is between 1 and 3 days (included)
                        },
                        'center',
                      )}
                    >
                      {(() => {
                        let diffHours = differenceInHours(new Date(), intervention.last_transaction_date)
                        let diffDays = Math.floor(diffHours / 24)
                        let remainingHours = diffHours % 24

                        if (diffDays > 0 && remainingHours > 0) {
                          return `${diffDays} jour(s) et ${remainingHours} heure(s)`
                        } else if (diffDays > 0) {
                          return `${diffDays} jour(s)`
                        } else {
                          return `${remainingHours} heure(s)`
                        }
                      })()}
                    </td>
                  ) : (
                    <td>Aucune transaction</td>
                  )}
                </tr>
              )
            })}

          <tr id="HP">
            <td colSpan={8} style={{ textAlign: 'center', color: '#FF0000 ', background: 'FF0000' }}>
              {' '}
              <h4>MACHINES HORS PRODUCTION</h4>
            </td>{' '}
          </tr>
          {formatted_interventions
            .filter((intervention) => {
              return intervention.comment !== ''
            })
            .map((intervention) => {
              return (
                <tr key={intervention.id}>
                  <td
                    className={classnames({
                      bg_danger: hasnt_responded(intervention),
                    })}
                  >
                    {intervention.horodatage}
                  </td>
                  <td>{intervention.machine_id}</td>
                  <td>{intervention.machine_location}</td>
                  <td
                    onClick={() => {
                      setmodalOpen(true)
                      setInterventionToComment(intervention)
                      setComment(intervention.comment)
                    }}
                  >
                    {intervention.comment}
                  </td>
                  <td
                    className={classnames(
                      {
                        bg_danger: intervention.STOCK < 50,
                        bg_warning: intervention.STOCK > 50 && intervention.STOCK < 250,
                      },
                      'weight',
                    )}
                  >
                    {intervention.STOCK} kg
                  </td>
                  <td
                    className={classnames(
                      {
                        bg_warning: intervention.TEMP_CF > 0,
                      },
                      'temperature',
                    )}
                  >
                    {intervention.TEMP_CF} °C
                  </td>
                  <td
                    className={classnames(
                      {
                        bg_warning: intervention.TEMP_CC > 60,
                      },
                      'temperature',
                    )}
                  >
                    {intervention.TEMP_CC} °C
                  </td>
                  {intervention.last_transaction_date ? (
                    <td // Color cell flag change refering to different time options
                      className={classnames({
                        bg_danger: differenceInDays(new Date(), intervention.last_transaction_date) > 3, //Danger flag if last transaction is over 3 days
                        bg_warning:
                          differenceInDays(new Date(), intervention.last_transaction_date) <= 3 &&
                          differenceInDays(new Date(), intervention.last_transaction_date) > 1, // Warning flag if last transaction is between 1 and 3 days (included)
                      })}
                    >
                      {(() => {
                        let diffHours = differenceInHours(new Date(), intervention.last_transaction_date)
                        let diffDays = Math.floor(diffHours / 24)
                        let remainingHours = diffHours % 24

                        if (diffDays > 0 && remainingHours > 0) {
                          return `${diffDays} jour(s) et ${remainingHours} heure(s)`
                        } else if (diffDays > 0) {
                          return `${diffDays} jour(s)`
                        } else {
                          return `${remainingHours} heure(s)`
                        }
                      })()}
                    </td>
                  ) : (
                    <td>Aucune transaction</td>
                  )}
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default Monitoring
